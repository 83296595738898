import React, { Component } from 'react';
import {
  Form, message, Button, Checkbox, Input, Icon,
} from 'antd';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { register, isConnected } from '../services/user';
import Loader from '../components/loader';
import LanguageSelect from '../components/languageSelect';
import '../styles/global.less';
import '../styles/login.less';
import '../styles/register.less';

class Register extends Component {
  state = { loading: false };

  componentDidMount() {
    if (isConnected()) {
      this.props.history.push('/dashboard');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        ReactGA.event({
          category: 'Register',
          action: 'click_register',
        });
        register(values.email.toLowerCase(), values.password, i18n.language.substring(0, 2))
          .then(() => {
            this.props.history.push('/introduction');
          })
          .catch(() => {
            message.error(this.props.t('errorRegisterPassword'));
          })
          .finally(() => this.setState({ loading: false }));
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.props.t('errorPasswordDifferent'));
    } else {
      callback();
    }
  };

  validatePassword = (rule, value, callback) => {
    if (value && value.length < 6) {
      callback(this.props.t('errorPasswordSize'));
    } else {
      callback();
    }
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    return (
      <div className="Login_global_wrapper">
        <div className="Register_wrapper">
          <div className="Affiliates_rules_wrapper">
            <h1 className="center">{t('rulesAffiliationTitle')}</h1>
            <div>
              <div className="One_rule">
                <div className="Number_rule popins">1</div>
                {' '}
                {t('affiliationRule1')}
              </div>
              <div className="One_rule">
                <div className="Number_rule popins">2</div>
                {' '}
                {t('affiliationRule2')}
              </div>
              <div className="One_rule">
                <div className="Number_rule popins">3</div>
                {' '}
                {t('affiliationRule3')}
              </div>
              <div className="One_rule">
                <div className="Number_rule popins">4</div>
                {' '}
                {t('affiliationRule4')}
              </div>
            </div>
          </div>
          <div className="Login_form_wrapper">
            <div className="Login_form_logo_wrapper">
              <img src="img/logo.png" className="Login_form_logo" alt="Logo" />
            </div>
            <div className="center">
              <h1>{t('affiliateProgram')}</h1>
            </div>

            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: t('errorEnterEmail') }],
                })(
                  <Input
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={t('placeholderEmail')}
                    type="email"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    { required: true, message: t('errorEnterPassword') },
                    {
                      validator: this.validatePassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={t('placeholderPassword')}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: t('errorConfirmPassword'),
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={t('placeholderPasswordConfirmation')}
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('agreement', {
                  valuePropName: 'checked',
                  initialValue: false,
                  rules: [
                    {
                      required: true,
                      message: t('acceptConditions'),
                      transform: value => value || undefined,
                      type: 'boolean',
                    },
                  ],
                })(
                  <Checkbox>
                    {t('IAcceptConditions1')}
                    <a
                      href="https://tokfluence.com/terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('IAcceptConditions2')}
                    </a>
                    .
                  </Checkbox>,
                )}
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  {t('createMyAccount')}
                </Button>
                <a className="Login_register_link" href="/#/login">
                  {t('IalreadyHaveAnAccount')}
                </a>
              </Form.Item>
            </Form>
            <div className="center widthAuto">
              <LanguageSelect />
            </div>
          </div>
        </div>
        {loading && <Loader />}
      </div>
    );
  }
}

Register.propTypes = {
  form: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
};

Register.defaultProps = {
  form: null,
  history: {},
  t: () => {},
};

export default Form.create({ name: 'login' })(withTranslation()(withRouter(Register)));
