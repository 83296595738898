import React, { Component } from 'react';
import {
  HashRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
import smartlookClient from 'smartlook-client';
import Login from './pages/login';
import Register from './pages/register';
import ForgetPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import Dashboard from './pages/dashboard';
import Loader from './components/loader';
import ConnectAs from './pages/connect-as';

import './components/i18n';

class Routing extends Component {
  state = { loading: false };

  componentDidMount() {
    smartlookClient.init('17963109db9633c0601eac1ad52a047c5c0205da');
    ReactGA.initialize('UA-148751073-1');
    // const options = {
    //   autoConfig: true,
    //   debug: false,
    // };
    // ReactPixel.init('391120268206787', null, options);
    // ReactPixel.pageView();
  }

  render() {
    const appProps = this.props;
    const { loading } = this.state;
    return (
      <>
        <HashRouter>
          <Switch>
            <Route path="/login" exact render={props => <Login {...appProps} {...props} />} />
            <Route path="/dashboard" exact render={props => <Dashboard {...appProps} {...props} />} />
            <Route path="/register" exact render={props => <Register {...appProps} {...props} />} />
            <Route
              path="/forgot-password"
              exact
              render={props => <ForgetPassword {...appProps} {...props} />}
            />
            <Route
              path="/reset-password/:resetPasswordToken"
              render={props => <ResetPassword {...appProps} {...props} />}
            />
            <Route
              path="/connect-as/:sessionId"
              exact
              render={props => <ConnectAs {...appProps} {...props} />}
            />
            <Redirect from="*" to="/login" />
          </Switch>
        </HashRouter>
        {loading && <Loader />}
      </>
    );
  }
}

export default Routing;
