import React, { Component } from 'react';
import { Menu, Icon, Button } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { logout } from '../services/user';
import LanguageSelect from './languageSelect';
import '../styles/headerDashboard.less';

const ROUTES = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'rise',
  },
];

class HeaderDashboard extends Component {
  getMenuKeys = () => {
    const currentPath = this.props.location.pathname;
    return ROUTES.filter(route => currentPath.indexOf(route.path) !== -1).map(route => route.path);
  };

  render() {
    const { history, t } = this.props;
    return (
      <div className="Header_dashboard_wrapper">
        <div className="Header_logo_wrapper">
          <a href="/">
            <img src="img/logo.png" alt="Logo Tokfluence" className="Header_logo" />
          </a>
          <Menu mode="horizontal" selectedKeys={this.getMenuKeys()} className="Header_menu">
            {ROUTES.map(route => (
              <Menu.Item key={route.path}>
                <Link to={route.path}>
                  <Icon type={route.icon} />
                  <span className="Text">{route.name}</span>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className="Header_language_select_wrapper">
          <LanguageSelect />
        </div>
        <div className="Header_actions_wrapper">
          <Button
            onClick={() => logout(() => {
              history.push('/login');
            })
            }
            type="link"
            size="small"
            style={{ color: '#24c7cc' }}
          >
            <Icon type="logout" />
            {t('disconnect')}
          </Button>
        </div>
      </div>
    );
  }
}

HeaderDashboard.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  t: PropTypes.func,
};

HeaderDashboard.defaultProps = {
  history: {},
  location: {},
  t: () => {},
};

export default withTranslation()(withRouter(HeaderDashboard));
