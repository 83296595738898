// eslint-disable-next-line import/prefer-default-export
export function parseSearch(search) {
  return (
    search
      .substr(1, Infinity) // Remove ?
      .split('&') // Split every fragment
      // Reduce
      .reduce((prev, fragment) => {
        const [key, value] = fragment.split('=');
        // eslint-disable-next-line no-param-reassign
        if (key) prev[key] = decodeURIComponent(value);
        return prev;
      }, {})
  );
}

export const getCountLabel = labelValue => (Math.abs(Number(labelValue)) >= 1.0e9
  ? `${(Math.abs(Number(labelValue)) / 1.0e9).toFixed(1)}B`
  : Math.abs(Number(labelValue)) >= 1.0e6
    ? `${(Math.abs(Number(labelValue)) / 1.0e6).toFixed(1)}M`
    : Math.abs(Number(labelValue)) >= 1.0e3
      ? `${(Math.abs(Number(labelValue)) / 1.0e3).toFixed(1)}K`
      : Math.abs(Number(labelValue)).toFixed(0));

export const get3charCountry = (countryCode) => {
  switch (countryCode) {
    case 'fr':
      return 'fra';
    case 'en':
    default:
      return 'eng';
  }
};

export const getSupportedLanguage = (lang) => {
  switch (lang) {
    case 'fr':
      return 'fr';
    case 'en':
    default:
      return 'en';
  }
};

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}
