import React, { Component } from 'react';
import { Typography, Table } from 'antd';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HeaderDashboard from '../components/headerDashboard';
import { getUserData, getDashboard } from '../services/user';
import '../styles/global.less';
import '../styles/dashboard.less';

const { Text } = Typography;

class Dashboard extends Component {
  state = {
    profile: {},
    data: {},
    columns: [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: date => Moment(date).format('DD/MM/YYYY'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: amount => (amount ? `${amount / 100}€` : '-'),
      },
    ],
  };

  componentDidMount() {
    getUserData().then((res) => {
      console.log('userdata', res.data);
      this.setState({ profile: res.data });
    }).catch((error) => {
      if (error && error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '#/login';
      }
    });

    getDashboard().then((res) => {
      console.log('getDashboard', res.data);
      const trialDate = new Date();
      trialDate.setDate(trialDate.getDate() - 3);
      this.setState({
        data: {
          ...res.data,
          history: res.data.history.reduce(
            (acc, el) => [...acc, { ...el, key: `${el.email}_${el.action}` }],
            [],
          ),
        },
      });
    });
  }

  render() {
    const {
      profile,
      data,
      columns,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <HeaderDashboard />
        <div className="Page_wrapper">
          <h1 className="center">{t('welcomeAffiliates')}</h1>
          <div className="Dashboard_container">
            <div className="Dashboard_vignette_wrapper">
              <div className="Dashboard_vignette Dashboard_link_wrapper">
                <h1>
                  {`${t('link')}${
                    profile.sources && profile.sources.length > 1 ? 's' : ''
                  }`}
                </h1>
                <div className="Link_wrapper">
                  <div>
                    {(profile.sources || []).map(source => (
                      <div className="Link_element" key={source}>
                        <Text copyable code>
                          {`https://tokfluence.com/?ref=${source}`}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="Dashboard_vignette Dashboard_balance_wrapper">
                <h1>Balance</h1>
                <div className="Amount_wrapper">
                  <div>
                    <div>
                      <span className="Amount popins">
                        {data.totalAmount >= 0
                          ? `${(
                            (data.totalAmount - profile.paid)
                            / 100
                          ).toFixed(2)}`
                          : '0'}
                      </span>
                      €
                    </div>
                    <div className="Minimum_payout_hint">
                      <i>
                        {t('minimumPayout')}
                        {' '}
50€
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Dashboard_table_wrapper">
              {data.summary && (
              <div className="flex">
                <h2 style={{ flexGrow: '1' }}>{t('history')}</h2>
                <div className="flex">
                  <div className="Table_number_vignette Table_signup">
                    FREE
:
                    <span className="popins">{data.summary.FREE}</span>
                  </div>
                  <div className="Table_number_vignette Table_signup">
                    STARTER
:
                    <span className="popins">{data.summary.STARTER}</span>

                  </div>
                  <div className="Table_number_vignette Table_payment">
                    BUSINESS
:
                    <span className="popins">{data.summary.BUSINESS}</span>
                  </div>
                </div>
              </div>
              )}
              <Table dataSource={data.history} columns={columns} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.func,
};

Dashboard.defaultProps = {
  t: () => { },
};

export default withTranslation()(Dashboard);
