import React, { Component } from 'react';
import {
  Form, Icon, Input, Button, message,
} from 'antd';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { login, isConnected } from '../services/user';
import { parseSearch } from '../services/utils';
import Loader from '../components/loader';
import LanguageSelect from '../components/languageSelect';
import '../styles/global.less';
import '../styles/login.less';

class Login extends Component {
  state = { loading: false };

  componentDidMount() {
    if (isConnected()) {
      this.props.history.push('/dashboard');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.form.validateFields((error, values) => {
      if (!error) {
        this.setState({ loading: true });
        ReactGA.event({
          category: 'Login',
          action: 'click_login',
        });
        login(values.email.toLowerCase(), values.password)
          .then(() => {
            this.setState({ loading: false });
            // eslint-disable-next-line react/prop-types
            const { next } = parseSearch(this.props.location.search);
            // eslint-disable-next-line react/prop-types
            this.props.history.push(next ? `/${decodeURIComponent(next)}` : '/dashboard');
          })
          .catch((err) => {
            const { t } = this.props;
            if (err && err.response && err.response.status === 404) {
              message.error(t('errorLoginEmail'));
            } else if (err && err.response && err.response.status === 401) {
              message.error(t('errorLoginPassword'));
            } else if (err && err.response && err.response.status === 422) {
              message.error(t('errorLoginVerify'));
            } else {
              message.error(t('errorUnknown'));
            }
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    return (
      <div className="Login_global_wrapper">
        <div className="Login_form_wrapper">
          <div className="Login_form_logo_wrapper">
            <img src="img/logo.png" className="Login_form_logo" alt="Logo" />
          </div>
          <div className="center">
            <h1>{t('affiliateProgram')}</h1>
          </div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: t('errorEnterEmail') }],
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={t('placeholderEmail')}
                  type="email"
                />,
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: t('errorEnterPassword') }],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder={t('placeholderPassword')}
                />,
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                {t('connect')}
              </Button>
              <a className="Login_register_link" href="/#/register">
                {t('noAccountYet')}
              </a>
              <a className="Login_forgot_link" href="/#/forgot-password">
                {t('ForgetPassword')}
              </a>
            </Form.Item>
          </Form>
          <div className="center widthAuto">
            <LanguageSelect />
          </div>
          {loading && <Loader />}
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  history: PropTypes.object,
  form: PropTypes.object,
  t: PropTypes.func,
};

Login.defaultProps = {
  history: {},
  form: null,
  t: () => {},
};

export default Form.create({ name: 'login' })(withTranslation()(withRouter(Login)));
