import React, { Component } from 'react';
import { Select } from 'antd';
import i18n from 'i18next';
import { getSupportedLanguage } from '../services/utils';
import '../styles/languageSelect.less';

class LanguageSelect extends Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    if (props.profile && i18n.language.substring(0, 2) !== props.profile.language) {
      i18n.changeLanguage(props.profile.language);
    }
    return state || {};
  }

  handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  render() {
    return (
      <div>
        <Select
          defaultValue={getSupportedLanguage(i18n.language.substring(0, 2))}
          className="Select_language"
          onChange={this.handleChangeLanguage}
        >
          <Select.Option value="fr">
            <span className="Language_image" role="img" aria-label="flag fr">
              🇫🇷
            </span>
          </Select.Option>
          <Select.Option value="en">
            <span className="Language_image" role="img" aria-label="flag en">
              🇺🇸
            </span>
          </Select.Option>
        </Select>
      </div>
    );
  }
}

export default LanguageSelect;
