/* eslint-disable max-len */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  fr: {
    translation: {
      errorEnterEmail: 'Entres ton email',
      placeholderEmail: 'Email',
      errorEnterPassword: 'Entre ton mot de passe',
      placeholderPassword: 'Password',
      connect: 'Connexion',
      ForgetPassword: 'Mot de passe oublié',
      errorLoginEmail: "Erreur lors du login : cet email n'existe pas.",
      errorLoginPassword: 'Erreur lors du login : mauvais mot de passe.',
      errorLoginVerify: 'Erreur lors du login : tu dois vérifier ton compte par email.',
      errorUnknown: 'Erreur inconnue.',
      errorConfirmPassword: 'Confirme ton mot de passe !',
      paymentError: 'Erreur lors du paiement',
      placeholderPasswordConfirmation: 'Mot de passe (confirmation)',
      acceptConditions: "Tu dois accepter les conditions d'utilisation.",
      IAcceptConditions1: "J'accepte les ",
      IAcceptConditions2: "conditions d'utilisation",
      createMyAccount: 'Créer mon compte',
      IalreadyHaveAnAccount: "J'ai déjà un compte",
      errorPasswordSize: 'Le mot de passe doit faire au moins 8 caractères.',
      errorPasswordDifferent: 'Les 2 mot de passe sont différents.',
      errorRegisterPassword: 'Erreur de connexion ! Vérifie ton email et ton mot de passe',
      enterNewPassword: 'Entres un nouveau mot de passe',
      errorEnterNewPassword: 'Entres ton Mot de passe!',
      placeholderNewPassword: 'Nouveau mot de passe',
      update: 'Mettre à jour',
      passwordUpdatedSuccess: 'Mot de passe mis à jour',
      errorLinkResetPasswordExpired: 'Le lien pour mettre ton mot de passe à jour a expiré.',
      forgotPassword: 'Mot de passe oublié',
      reinitPassword: 'Réinitialiser mon mot de passe',
      forgotPasswordSuccess: "Nous venons de t'envoyer un mail avec les instructions.",
      errorNoAccountWithThisEmail: "Aucun compte n'est relié à cet email.",
      welcome: 'Bienvenue',
      informationsBeforeStart: 'Quelques informations avant de démarrer:',
      informationsBeforeStart1:
        "Jeffrey pratique du follow/unfollow donc votre nombre d'abonnements va monter puis redescendre de façon cyclique.",
      informationsBeforeStart2:
        'La connexion entre Jeffrey et votre compte Tiktok est complètement sécurisée et automatisée.',
      letsgo: "C'est parti",
      disconnect: 'Se déconnecter',
      configurationTitle: 'Configuration',
      configurationTitle1: 'des comptes similaires à cibler',
      configurationHint1: "Entres ici 3 comptes Tiktok d'influenceurs.",
      configurationHint2:
        "Ils doivent être dans ton style et avoir beaucoup de followers (L'idéal est plus de 50k,",
      configurationHint3: 'le minimum est 5k followers',
      configurationHint4: "Tu pourras ajouter d'autres cibles une fois sur ton dashboard.",
      configurationHint5: 'Jeffrey ira cibler les followers de ces comptes cibles.',
      enterAccountName: 'Entres le nom du compte cible à ajouter',
      validate: 'Valider',
      areYouSureDeleteCompetitor: 'Êtes-vous sûr de vouloir supprimer ce compte ?',
      yes: 'Oui',
      no: 'Non',
      errorSearch: 'Erreur lors de la recherche',
      privateAccount: 'Ce compte est privé',
      notEnoughFollowers: "Ce compte n'a pas assez de followers (min 5k pour un compte cible)",
      errorAccountAlreadyInList: 'Ce compte est déjà dans votre liste.',
      errorLoadingData: 'Erreur lors du chargement des données',
      errorPaymentDescription:
        'Un erreur a eu lieu lors du paiement. Afin de redémarrer votre abonnement vous devez procéder au paiement.',
      proceedToPayment: 'Procéder au paiement',
      active: 'Actif',
      addAnAccount: 'Ajouter un compte',
      date: 'Date',
      price: 'Prix',
      invoice: 'Facture',
      seeRefund: "Voir l'avoir",
      seeInvoice: 'Voir la facture',
      renewDeactivateSuccess: 'Le renouvellement automatique a été désactivé.',
      DeactivateError: "Erreur lors de l'annulation du renouvellement. Veuillez ré-essayer.",
      stopTrialSuccess: 'Votre essai gratuit est arrêté',
      stopTrialError: "Erreur lors de l'arrêt. Veuillez ré-essayer.",
      sureWantToStop: 'Êtes-vous sûr de arrêter ?',
      subscriptionPageTitle: 'Abonnement en cours',
      trial: 'Essai gratuit',
      autoRenewIsDeactivated:
        "Le renouvellement automatique est désactivé. L'abonnement ne sera donc pas reconduit.",
      nextRenewal: 'Prochain renouvellement',
      stopRenewAuto: 'Arrêter le renouvellement automatique',
      the: 'le',
      until: "Jusqu'au",
      afterTrialHint:
        "Après ton essai gratuit tu pourras souscrire à l'abonnement de ton choix. Tu peux aussi souscrire un abonnement dès maintenant si tu le souhaites.",
      afterTrialHintPromo:
        "Voici un code promo de 25% qui est valable jusqu'à la fin de ton essai gratuit:",
      subscribeToASubscription: 'Souscrire un abonnement',
      IWantToStop: "Je veux arrêter l'essai",
      billingInformationTitle: 'Informations de facturation',
      updateBillingInformation: 'Mettre à jour mes informations de facturation',
      invoices: 'Factures',
      saveSuccess: 'Sauvegarde réussie !',
      VATinvalid: "Le numéro de TVA intra n'est pas valide.",
      saveError: 'Erreur lors de la sauvegarde',
      myProfile: 'Mon profil',
      nameOrCompany: 'Nom ou entreprise',
      nameOrCompanyError: "Indique ton prénom et ton nom ou le nom de l'entreprise !",
      street: 'Rue',
      streetError: 'Indique une rue !',
      postalCode: 'Code postal',
      postalCodeError: 'Indique un code postal !',
      city: 'Ville',
      cityError: 'Indique une ville !',
      country: 'Pays',
      countryError: 'Indique un pays !',
      state: 'État',
      stateError: 'Indique un état !',
      vat: 'TVA Intracommunautaire',
      vatPlaceholder: 'Numéro de TVA Intracommunautaire',
      cancel: 'Annuler',
      save: 'Sauvegarder',
      expiredSubscription:
        'Votre dernier abonnement a expiré. Veuillez cliquer ici pour relancer Jeffrey.',
      relaunchJeffrey: 'Relancer Jeffrey',
      errorBuying: "Erreur lors de l'achat, veuillez recommencer",
      promoCodeAddedSuccess: 'Code promo ajouté',
      promoCodeErrorNotExist: "Ce code promo n'existe pas",
      promoCodeError: "Erreur lors de l'ajout du code promo",
      subscription: 'Abonnement',
      noEngagement: 'Tous les abonnements sont sans engagement.',
      trustpilotNumbers: '4.8/5 - 145 avis (Source Trustpilot)',
      seeTrustpilot: 'Voir les avis',
      availableSoon: 'Bientôt disponible',
      popular: 'populaire',
      month: 'mois',
      acceptCGV: 'Vous devez accepter les conditions générales de vente.',
      cgvFulltext: 'conditions générales de vente',
      enterPaymentInformation: 'Entres tes informations de paiement ici:',
      securedByStripe: 'Formulaire sécurisé par Stripe',
      enterPromoCode: 'Entrez un code promo',
      change: 'Changer',
      choose: 'Choisir',
      supportMessageSent: "Message envoyé à l'équipe support",
      errorSupportMessageSent: 'Une erreur a eu lieu lors de lenvoi du message.',
      contactSupport: 'Contacter le support',
      send: 'Envoyer',
      jeffreyIsHereForYou: 'Un problème ? Tokfluence est là pour vous',
      beforeSendingMyMessage: "Avant d'envoyer mon message:",
      pleaseValidateThisPoint: 'Veuillez valider ce point.',
      supportRemember1: "J'ai raffraichi ma page (si mon problème concerne une erreur)",
      supportRemember2: "J'ai bien tenté de patienter mais le problème persiste",
      supportRemember3: "J'ai bien noté le message d'erreur pour le communiquer",
      enterAMessage: 'Entrez un message.',
      placeholderSupportMessage: 'Décrivez votre problème et ses conditions exactes.',
      answerFastSupport: 'Réponse dans la journée lors de jours ouvrés.',
      titleGraph: 'Évolution des followers gagnés',
      legendGraph: 'Nb de followers',
      errorMinimumCompetitors: 'Vous devez avoir au moins 1 competitor.',
      deleteCompetitorSuccess: 'Compte supprimé.',
      deleteCompetitorError: 'Erreur lors de la suppression',
      addCompetitorSuccess: 'Compte ajouté.',
      addCompetitorError: "Erreur lors de l'ajout",
      deleteThisAccountConfirmation: 'Êtes-vous sûr de vouloir supprimer ce compte ?',
      addCountrySuccess: 'Pays ajouté.',
      addCountryError: "Erreur lors de l'ajout du pays.",
      days: 'jours',
      titleTotalOfFollowers: 'Followers gagnés depuis les 30 derniers jours grâce à Jeffrey',
      titleCompetitors: 'Comptes à cibler',
      competitorExplanation:
        "Entres ici 3 comptes Tiktok d'influenceurs. Ils doivent être dans ton style et avoir beaucoup de followers (L'idéal est plus de 50k,",
      followbackExplanation:
        " Le chiffre sur chaque compte correspond au taux de follow-back. C'est à dire au pourcentage de personnes qui te suivent en retour du fait que tu les as suivies.",
      followbackExplanation1:
        "Si le chiffre n'est pas encore présent c'est qu'il n'y a pas encore assez de données.",
      targetedCountriesTitle: 'Pays cibles',
      targetedCountriesDescription: 'Entres ici les pays que tu veux viser.',
      selectCountryPlaceholder: 'Sélectionne un pays',
      noResult: 'Aucun résultat',
      numberOfCompetitorshint: 'Entre encore ',
      numberOfCompetitorshint1Plural: ' comptes pour valider.',
      numberOfCompetitorshint1Singular: ' compte pour valider.',
      connectTiktokTitle: 'Connecte ton compte Tiktok',
      securitySuperImportant1: 'La sécurité de ton compte est très importante pour nous.',
      securitySuperImportant2:
        'Nous ne stockerons pas ton mot de passe après ton inscription. Le mot de passe est uniquement requis pour connecter notre solution à Tiktok.',
      username: "Nom d'utilisateur",
      enterTiktokUsername: 'Entrez votre username Tiktok!',
      tiktokUsername: 'Username Tiktok',
      tiktokPassword: 'Mot de passe Tiktok',
      noPasswordGoogle1: "Je n'ai pas de mot de passe",
      noPasswordGoogle2: "car j'ai créé mon compte Tiktok avec Google",
      noPasswordGoogle3:
        "Si tu as créé ton compte via la connexion Google, tu n'as pas encore de mot de passe Tiktok. Pour le créer, tu dois aller dans ton compte Tiktok,",
      noPasswordGoogle4:
        'clique sur Paramètres (icone en haut à droite) puis Gérer mon compte > Mot de passe.',
      noPasswordGoogle5: 'Crée ton mot de passe et voilà, le tour est joué.',
      enterCaptcha: 'Entre le captcha',
      placeholderCaptcha: 'Captcha de sécurité',
      errorTiktokLoginAlreadyLinked:
        "Erreur lors de l'ajout du compte: Ce compte est déjà relié à un compte jeffrey.",
      errorTiktokLoginUnknownUser: "Erreur lors du login : cet utilisateur n'existe pas.",
      errorTiktokLoginWrongPassword: 'Erreur lors du login : mauvais compte ou mot de passe.',
      errorTiktokLoginCaptcha: 'Erreur lors du login : vérifiez le captcha',
      errorTiktokLoginAt: 'Errur: Le username ne peut pas contenir de @',
      followersAreComing: 'Vos followers sont en route.',
      followersAreComing2: 'Jeffrey y travaille sans relâche.',
      for: 'pour',
      affiliateProgram: 'Programme Affilié',
      rulesAffiliationTitle: "Les règles de l'affiliation",
      affiliationRule1: 'Vous gagnez 20% des dépenses des clients que vous amenez chez Tokfluence.',
      affiliationRule2:
        "Ceci n'est pas destiné à un usage personnel. Tout compte personnel utilisé entraînera une suppression de votre compte affilié.",
      affiliationRule3:
        'Le seuil nécessaire pour le paiement est de 50€. Transfère Paypal si vous êtes un professionnel (contre une facture de votre part), carte cadeau Amazon sinon.',
      affiliationRule4:
        "Pour promouvoir Tokfluence, vous devez absolument utiliser le lien d'affiliation disponible sur votre dashboard.",
      welcomeAffiliates: "Bienvenue au programme d'affiliation",
      link: 'Lien',
      alreadyPaid: 'Déjà payé',
      minimumPayout: 'Le montant minimum pour paiement est de',
      payments: 'PAIEMENTS',
      signups: 'INSCRIPTIONS',
      history: 'Historique',
      trials: 'ESSAIS GRATUITS',
      inprogress: 'en cours',
      RegistrationsClosed: 'Les inscriptions sont définitivement fermées',

    },
  },
  en: {
    translation: {
      noAccountYet: 'I don\'t have any account yet',
      errorEnterEmail: 'Enter your email!',
      placeholderEmail: 'Email',
      errorEnterPassword: 'Enter your password !',
      placeholderPassword: 'Password',
      connect: 'Login',
      ForgetPassword: 'Forgot password',
      errorLoginEmail: 'Login error : this email does not exist.',
      errorLoginPassword: 'Login error : Wrong password.',
      errorLoginVerify: 'Login error : You must verify your account by email.',
      errorUnknown: 'Unknown error.',
      errorConfirmPassword: 'Please confirm your password !',
      paymentError: 'Error while processing payment',
      placeholderPasswordConfirmation: 'Password (confirmation)',
      acceptConditions: 'You must accept the terms of use.',
      IAcceptConditions1: 'I accept the ',
      IAcceptConditions2: 'terms and conditions',
      createMyAccount: 'Create my account',
      IalreadyHaveAnAccount: 'I already have an account',
      errorPasswordSize: 'The paswword must have minimum 8 characters.',
      errorPasswordDifferent: 'Both password are different.',
      errorRegisterPassword: 'Login error ! Verify your email and password',
      enterNewPassword: 'Enter a new password',
      errorEnterNewPassword: 'Enter your password!',
      placeholderNewPassword: 'New password',
      update: 'Update',
      passwordUpdatedSuccess: 'Password updated',
      errorLinkResetPasswordExpired: 'The link for updating your password has expired.',
      forgotPassword: 'Forgot password',
      reinitPassword: 'Re-init my password',
      forgotPasswordSuccess: 'We just sent you an email with the instructions.',
      errorNoAccountWithThisEmail: 'There is no account with this email.',
      welcome: 'Welcome',
      informationsBeforeStart: 'Some information before you start:',
      informationsBeforeStart1:
        'Jeffrey performs follow / unfollow automation so your number of following will grow and go down cyclically.',
      informationsBeforeStart2:
        'The connexion between Jeffrey and your Tiktok account is totally secured and automated.',
      letsgo: "Let's go",
      disconnect: 'Log out',
      configurationTitle: 'Configuration',
      configurationTitle1: 'of the competitor accounts to target',
      configurationHint1: 'Enter here 3 Tiktok influencer accounts.',
      configurationHint2:
        'They must be in your style and have a lot of followers (The ideal is over 50k,',
      configurationHint3: 'the minimum is 5k followers',
      configurationHint4: "You'll be able to enter other target once on your dashboard.",
      configurationHint5: 'Jeffrey uses these accounts to target their folllowers.',
      enterAccountName: 'Enter the name of the target to add',
      validate: 'Validate',
      areYouSureDeleteCompetitor: 'Are you sure you want to delete this account ?',
      yes: 'Yes',
      no: 'No',
      errorSearch: 'Search error',
      privateAccount: 'This account is private',
      notEnoughFollowers:
        'This account does not have enough followers (min 5k for a targetted account)',
      errorAccountAlreadyInList: 'This account is already in your list.',
      errorLoadingData: 'Error while loading the data',
      errorPaymentDescription:
        'There was an error during the payment. To resume your subscription you must perform the payment.',
      proceedToPayment: 'Perform the payment',
      active: 'Active',
      addAnAccount: 'Add an account',
      date: 'Date',
      price: 'Price',
      invoice: 'Invoice',
      seeRefund: 'View the refund',
      seeInvoice: 'View the invoice',
      renewDeactivateSuccess: 'Automatic renewal has been deactivated.',
      DeactivateError: 'Error while cancelling the renewal. Please try again.',
      stopTrialSuccess: 'Your free trial has stopped',
      stopTrialError: 'Error while stopping. Please try again.',
      sureWantToStop: 'Are you sure you want to stop ?',
      subscriptionPageTitle: 'Subscription in progress',
      trial: 'Free trial',
      autoRenewIsDeactivated:
        'Automatic renewal is disabled. The subscription will not be renewed.',
      nextRenewal: 'Next renewal',
      stopRenewAuto: 'Stop the automatic renewal',
      the: 'the',
      until: 'Until',
      afterTrialHint:
        'After your free trial you will be able to subscribe to the subscription of your choice. You can also subscribe now if you wish.',
      afterTrialHintPromo:
        'Here is a 25% promo code which is valid until the end of your free trial:',
      subscribeToASubscription: 'Subscribe',
      IWantToStop: 'I want to stop the free trial',
      billingInformationTitle: 'Billing information',
      updateBillingInformation: 'Update my billing information',
      invoices: 'Invoices',
      saveSuccess: 'Save success !',
      VATinvalid: 'The VAT-ID number is not valid.',
      saveError: 'Error while saving',
      myProfile: 'My profile',
      nameOrCompany: 'Name or company',
      nameOrCompanyError: 'Fill in your first name and your name or the name of the company!',
      street: 'Street',
      streetError: 'Fill in a street !',
      postalCode: 'Postal code',
      postalCodeError: 'Fill in a postal code !',
      city: 'City',
      cityError: 'Fill in a city !',
      country: 'Country',
      countryError: 'Fill in a country !',
      state: 'State',
      stateError: 'Fill in a state !',
      vat: 'VAT-ID (optional)',
      vatPlaceholder: 'VAT-ID (optional)',
      cancel: 'Cancel',
      save: 'Save',
      expiredSubscription:
        'Your last subscription has expired. Please click here to relaunch Jeffrey.',
      relaunchJeffrey: 'Relaunch Jeffrey',
      errorBuying: 'Error buying, please try again',
      promoCodeAddedSuccess: 'Promo code added',
      promoCodeErrorNotExist: 'This promo code does not exist',
      promoCodeError: 'Error while adding the promo code',
      subscription: 'Subscription',
      noEngagement: 'All subscription are without subscription.',
      trustpilotNumbers: '4.8/5 - 145 reviews (Source Trustpilot)',
      seeTrustpilot: 'See the reviews',
      availableSoon: 'Available soon',
      popular: 'popular',
      month: 'month',
      acceptCGV: 'You must accept the terms of sales.',
      cgvFulltext: 'Terms of sales',
      enterPaymentInformation: 'Enter your payment information here:',
      securedByStripe: 'Form secured by stripe',
      enterPromoCode: 'Enter a promo code',
      change: 'Change',
      choose: 'Choose',
      supportMessageSent: 'Message sent to the support team',
      errorSupportMessageSent: 'An error happened while sending the message.',
      contactSupport: 'Contact the support',
      send: 'Send',
      jeffreyIsHereForYou: 'Any issue ? Tokfluence is here for you',
      beforeSendingMyMessage: 'Before sending my message:',
      pleaseValidateThisPoint: 'Please validate this point.',
      supportRemember1: 'I refreshed the page (if my issue regards an error)',
      supportRemember2: 'I tried to wait and retry but the issue persists.',
      supportRemember3: 'I saved the error message so I can communicate it',
      enterAMessage: 'Enter a message',
      placeholderSupportMessage: 'Describe your issue and its exact conditions',
      answerFastSupport: 'Answer within the day during openning days.',
      titleGraph: 'Evolution of followers earned',
      legendGraph: 'Nb of followers',
      errorMinimumCompetitors: 'You must have at least 1 competitor.',
      deleteCompetitorSuccess: 'Account deleted.',
      deleteCompetitorError: 'Error while deleting the account',
      addCompetitorSuccess: 'Account added.',
      addCompetitorError: 'Error while adding the account',
      deleteThisAccountConfirmation: 'Are you sure you want to delete this account ?',
      addCountrySuccess: 'Country added.',
      addCountryError: 'Error while adding the country.',
      days: 'days',
      titleTotalOfFollowers: 'Followers won in the last 30 days thanks to Jeffrey',
      titleCompetitors: 'Targeted accounts',
      competitorExplanation:
        'Enter here 3 influencers Tiktok accounts. They must be in your style and have a lot of followers (The ideal is over 50k,',
      followbackExplanation:
        ' The number on each account is the follow-back rate. Which means, the percentage of people who followed you back because you followed them.',
      followbackExplanation1: 'If there is no number, it means there is not enough data yet.',
      targetedCountriesTitle: 'Targeted countries',
      targetedCountriesDescription: 'Enter here the countries you want to target.',
      selectCountryPlaceholder: 'Select a country',
      noResult: 'No result',
      numberOfCompetitorshint: 'Enter ',
      numberOfCompetitorshint1Plural: ' more accounts to validate.',
      numberOfCompetitorshint1Singular: ' more account to validate.',
      connectTiktokTitle: 'Connect your Tiktok account',
      securitySuperImportant1: 'The security of your account is very important for us.',
      securitySuperImportant2:
        'We will not store your password after your registration. The password is needed to connect our solution to Tiktok.',
      username: 'Username',
      enterTiktokUsername: 'Enter your Tiktok username',
      tiktokUsername: 'Tiktok username',
      tiktokPassword: 'Tiktok password',
      noPasswordGoogle1: "I don't have any password",
      noPasswordGoogle2: 'because I created my Tiktok account with Google.',
      noPasswordGoogle3:
        "If you created your account via Google connexion, you don't have any Tiktok password yet. To create it, you need to go on your Tiktok account,",
      noPasswordGoogle4: 'click on Parameters (top right icon) then Manage my account > Password.',
      noPasswordGoogle5: "Create your password and that's it !",
      enterCaptcha: 'Enter the captcha',
      placeholderCaptcha: 'Security captcha',
      errorTiktokLoginAlreadyLinked:
        'Error while adding account: This account is already linked to another Jeffrey account',
      errorTiktokLoginUnknownUser: "Error while connecting: this user doesn't exist.",
      errorTiktokLoginWrongPassword: 'Error while connecting: wrong account or password.',
      errorTiktokLoginCaptcha: 'Error while connecting: verify the captcha',
      errorTiktokLoginAt: "Error: username can't contain any @",
      followersAreComing: 'Brace youself',
      followersAreComing2: 'Followers are coming',
      for: 'for',
      affiliateProgram: 'Affiliate program',
      rulesAffiliationTitle: 'Rules of affiliation',
      affiliationRule1: "You will earn 20% of the expenses of the clients you'll bring to Tokfluence.",
      affiliationRule2:
        'This is not for a personal use. Every personal account used will end up by a removal of the affiliate account.',
      affiliationRule3:
        'The minimum amount necessary for the payment is 50€. Paypal transfer if you are a professional (invoice needed from you), Amazon gift card otherwise.',
      affiliationRule4:
        'To promote Tokfluence you have to use the affiliation link provided in your dashboard.',
      welcomeAffiliates: 'Welcome to the affiliate program',
      link: 'Link',
      alreadyPaid: 'Already paid',
      minimumPayout: 'Minimum payout is',
      payments: 'PAYMENTS',
      signups: 'SIGNUPS',
      history: 'History',
      trials: 'TRIALS',
      inprogress: 'in progress',
      RegistrationsClosed: 'Registrations are permanently closed',

    },
  },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: 'en',
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
