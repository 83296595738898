/* eslint-disable max-len */
import Axios from 'axios';
import { API_URL } from './constants';
import { getCookie } from './utils';

export const isTest = window.location.hostname.indexOf('localhost') >= 0;

export const getSessionId = () => localStorage.getItem('sessionId');

export const isConnected = () => localStorage.getItem('sessionId')
  && localStorage.getItem('sessionId') != null
  && localStorage.getItem('sessionId') !== 'undefined';

export const login = (email, password) => Axios.post(`${API_URL}/login`, { email, password }).then(response => localStorage.setItem('sessionId', response.data.sessionId));

export const register = (email, password, language) => Axios.post(`${API_URL}/register`, {
  email, password, language, source: getCookie('tiktokref'),
}).then(response => localStorage.setItem('sessionId', response.data.sessions[0]));

export const forgetPassword = email => Axios.post(`${API_URL}/forget-password`, { email });

export const resetPassword = (password, resetPasswordToken) => Axios.post(`${API_URL}/reset-password`, { password, resetPasswordToken }).then((data) => {
  localStorage.setItem('sessionId', data.data.sessionId);
});

export const logout = redirectHome => Axios.post(`${API_URL}/logout`, null, {
  headers: {
    sessionId: getSessionId(),
  },
})
  .then(() => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('type');
    redirectHome();
  })
  .catch(() => {
    localStorage.removeItem('sessionId');
    localStorage.removeItem('type');
    redirectHome();
  });

export const getUserData = () => Axios.post(`${API_URL}/get-profile`, null, {
  headers: { sessionId: getSessionId() },
});
export const getDashboard = () => Axios.post(`${API_URL}/get-dashboard`, null, {
  headers: { sessionId: getSessionId() },
});

export const contactSupport = message => Axios.post(
  `${API_URL}/contact-support`,
  { message },
  {
    headers: { sessionId: getSessionId() },
  },
);

export const changeLanguage = lang => Axios.post(
  `${API_URL}/update-language`,
  { language: lang },
  {
    headers: { sessionId: getSessionId() },
  },
);
